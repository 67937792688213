import React, { useContext, useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

import { Box, Tabs, Tab, Grid, Avatar, Text, Button } from "grommet";
import SEO from "../../components/Seo";
import Footer from "../../components/Footer";
import Arrow from "../../assets/svgs/Chevron-right.svg";
import Question from "../../assets/svgs/Question.svg";
import Check from "../../assets/svgs/Check_circle.svg";
import Data from "./data.yml";
import { getReferrer } from "../../hooks/utils";
import SoftwareModal from "../../components/Modal/SoftwareModal";
import "./index.css";

let annualPrice: number = 4400;
let triPrice: number = 4400 * 0.9;

const IndexPage = (props) => {
  const [priceChoice, setPriceChoice] = useState(`${annualPrice}`);
  const [showModal, setShowModal] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const priceSelect = (price: string) => setPriceChoice(price);
  const dispatch = useContext(GlobalDispatchContext);

  const Row = styled.div`
    display: grid;
    gap: 32px;
    padding: 24px 16px;
    @media (min-width: 1440px) {
      grid-template-columns: auto 1fr;
      gap: 64px;
    }
  `;
  const Requirments = styled.div`
    display: grid;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6b7280;
    }

    b {
      color: #111827;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    @media (min-width: 768px) {
      grid-template-columns: 200px 1fr;
      * {
        padding: 24px 0;
      }
    }
  `;
  const Divider = styled.div`
    width: 100%;
    height: 2px;
    background: #e5e7eb;
  `;

  const handleDefault = () => {
    navigate("/contact?ref=null");
  };

  useEffect(() => {
    let ref: string = getReferrer();
    setReferrer(ref);
  }, []);

  return (
    <div>
      {/* Header */}
      <main className="overflow-x-hidden">
        {/* Hero Section */}
        <section className="pt-10 md:pt-[57px] pb-9">
          <div className="tw-container">
            <div className="hero_inner grid lg:grid-cols-2	">
              <div>
                <label className="inline-flex items-center gap-1 py-1 px-2 md:px-3 rounded-full bg-main-primary text-xs text-black font-medium tracking-[0.3px] uppercase mb-4 md:mb-6 mr-5">
                  BIM Software
                </label>
                <Link
                  to="/services/playbook-contractor"
                  className="text-black text-[14px] "
                >
                  Professional Services <Arrow className="ml-2" />
                </Link>
                <h1 className="font-bold text-5xl sm:text-6xl text-black">
                  Buildings Worksuite:{" "}
                  <span className="">BIM Software</span> for
                  Professionals.
                </h1>
                <p className="font-[20px] leading-7	">
                  Design, Analyse, and Build high performance 3D
                  models for infrastructure projects.
                </p>
                <div className="flex flex-wrap gap-5 items-center">
                  <button
                    className="rounded-md bg-[#FBDA05] py-3 px-4 font-[500] leading-6 text-[16px]"
                    onClick={() => handleDefault()}
                  >
                    Get a Quote
                  </button>
                </div>
                <p className="text-xs">
                  *Up 30-day payment terms for businesses
                </p>
              </div>
              <div>
                <StaticImage
                  src="../../images/hero.png"
                  alt="Hero image"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Pricing section */}
        <section className="price_section pt-10 md:pt-[57px] pb-9 ">
          <div className="tw-container">
            <div className="text-center">
              <h2 className="font-[800] text-5xl text-gray-900 my-4">
                BIM Software Price
              </h2>
              {/* <p className="text-gray-600 font-[20px] leading-7	">
                Buy BIM software with cost-effective subscription
                pricing
              </p> */}
            </div>

            <div className="Content price_box grid max-w[1234px] bg-white rounded-lg shadow-lg">
              <div className="p-4 md:p-6 lg:p-12">
                <h3 className="my-0 text-3xl leading-9 font-extrabold text-gray-900">
                  Buy Buildings Worksuite
                </h3>
                <p className="my-0 text-base leading-6 font-normal text-gray-500">
                  The OpenBuildings Worksuite equips construction
                  professionals with digital tools throughout the
                  design, construction, and handover phases of complex
                  infrastructure projects.{" "}
                </p>
                <div className="choice_box grid lg:grid-cols-3 py-[18px]">
                  <div
                    className={
                      "price_card" +
                      (priceChoice == `${annualPrice}`
                        ? " active"
                        : "")
                    }
                    onClick={() => priceSelect(`${annualPrice}`)}
                  >
                    <p className="duration">1 Year</p>
                    <div className="price_cell">
                      <span className="price">$4,400</span>
                      <div className="grid ">
                        <span className="text-sm leading-5 font-normal text-gray-900">
                          SGD / Year
                        </span>
                        {/* <span className="text-sm leading-5 font-normal text-gray-500">
                          Billed yearly ($220)
                        </span> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "price_card" +
                      (priceChoice === `${triPrice}` ? " active" : "")
                    }
                    onClick={() => priceSelect(`${triPrice}`)}
                  >
                    <p className="duration">3 Year</p>
                    <div className="price_cell">
                      <span className="price">$3,933</span>
                      <div className="grid">
                        <span className="text-sm leading-5 font-normal text-gray-900">
                          SGD / Year
                        </span>
                        {/* <span className="text-sm leading-5 font-normal text-gray-500">
                          Billed triennial ($225)
                        </span> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "price_card" +
                      (priceChoice === "Get Quote" ? " active" : "")
                    }
                    onClick={() => priceSelect("Get Quote")}
                  >
                    <p className="duration">Projects</p>
                    <div className="price_cel">
                      <span className="price">Get Quote</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex gap-5 items-center">
                    <h4 className="text-sm leading-5 font-semibold tracking-wider uppercase w-fit min-w-36">
                      What’s included
                    </h4>
                    <Divider />
                  </div>
                  <div className="grid lg:grid-cols-2">
                    {Data.price_includs.map((txt: string, i) => (
                      <div className="flex align-center" key={i}>
                        <Check />
                        <span
                          key={i}
                          className="text-sm leading-5 font-normal text-gray-700 ml-5"
                        >
                          {txt}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="text-black p-5 lg:p-12 gap-6 flex flex-col items-center justify-center text-center rounded-br-md	rounded-tr-md	">
                <div>
                  <p className="text-lg leading-6 font-medium">
                    Pay once, own it for a year
                  </p>
                  <div className="flex flex-wrap justify-center gap-2 items-center">
                    <span className="text-5xl leading-none font-extrabold tracking-tight">
                      {priceChoice}
                    </span>
                    <span className="text-xl leading-7 font-medium">
                      {priceChoice !== "Get Quote"
                        ? "SGD / year"
                        : ""}
                    </span>
                  </div>
                  <p className="text-sm leading-5 font-normal text-gray-500">
                    (For a Single User)
                  </p>
                </div>
                {/* <Link
                  to="#"
                  className="text-sm leading-5 font-medium underline text-[#FBDA05]"
                >
                  Learn about our membership policy
                </Link> */}

                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="bg-[#FBDA05] rounded-md py-3 px-5 text-black"
                >
                  Get a Quote
                </button>

                <p>
                  Taxes not included. Purchase includes 3 Keys{" "}
                  <Question />
                </p>
              </div>
            </div>
          </div>

          <div className="tw-container bg-gray-100 p-10 max-w-[1234px] rounded-lg mt-9">
            <h3 className="text-sm leading-5 font-medium tracking-wide uppercase text-gray-800 bg-white py-1 px-4 rounded-2xl w-fit">
              About this software subscription
            </h3>
            <p className="text-lg leading-7 font-normal text-gray-800">
              This Virtuoso Subscription includes a 12-month
              practitioner license for this software that comes with
              <a
                href="https://en.virtuosity.com/virtuoso-subscription-keys"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                 Keys 
              </a>
              (credits) to unlock training and services.
            </p>
          </div>
        </section>

        <section className="bg-gray-50">
          <div className="tw-container">
            <Tabs>
              <Tab title="Overview" className="choose_tab">
                <Box pad="medium" className="py-24">
                  <Grid className="lg:grid-cols-2 " gap="32px">
                    <Box>
                      <p className="text-[16px] text-[#003591] uppercase font-semibold ">
                        Everything you need
                      </p>
                      <h2 className="text-3xl text-gray-900 font-extrabold">
                        All-in-one convenient solution set
                      </h2>
                      <p className="text-lg leading-7 font-normal text-gray-500">
                        Introducing Building WorkSuite, the most
                        comprehensive building and site design and
                        analysis software bundle. It includes a set of
                        robust applications that work seamlessly
                        together in a single, cost-effective license.
                      </p>
                      <p className="text-lg leading-7 font-normal text-gray-500">
                        With the right capabilities right where you
                        need them, when you need them, you can deliver
                        complete projects with more efficiency,
                        accuracy, and innovation–and deliver growth to
                        your bottom line. Get started today.
                      </p>
                      <div className="items grid">
                        <div className="singleItem">
                          <div className="w-12 box-border	h-12 p-3 bg-[#003591] rounded-md">
                            <img
                              src="/icons/heroicons--home-modern.svg"
                              loading="lazy"
                              alt="icon"
                              className=""
                            />
                          </div>
                          <div>
                            <h3 className="my-0 text-lg text-gray-900 leading-6 font-medium">
                              Comprehensive Building and Site Project
                              Management
                            </h3>
                            <p className="text-gray-500 text-base leading-6 font-normal">
                              Design, document, visualize, and
                              communicate every aspect of a building
                              and site project.
                            </p>
                          </div>
                        </div>
                        <div className="singleItem flex gap-5">
                          <div className="w-12 box-border	h-12 p-3 bg-[#003591] rounded-md">
                            <img
                              src="/icons/heroicons--user-group.svg"
                              loading="lazy"
                              alt="icon"
                              className=""
                            />
                          </div>
                          <div>
                            <h3 className="my-0 text-lg text-gray-900 leading-6 font-medium">
                              Real-Time Collaboration Management
                            </h3>
                            <p className="text-gray-500 text-base leading-6 font-normal">
                              Facilitate real-time collaboration with
                              stakeholders.
                            </p>
                          </div>
                        </div>
                        <div className="singleItem flex gap-5">
                          <div className="w-12 box-border	h-12 p-3 bg-[#003591] rounded-md">
                            <img
                              src="/icons/flash.svg"
                              loading="lazy"
                              alt="icon"
                              className=""
                            />
                          </div>
                          <div>
                            <h3 className="my-0 text-lg text-gray-900 leading-6 font-medium">
                              Enhanced Project Efficiency
                            </h3>
                            <p className="text-gray-500 text-base leading-6 font-normal">
                              Reduce errors, save design time, and
                              avoid project delays.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Box>
                    <StaticImage
                      src="../../assets/images/software/Image.png"
                      alt="illustration"
                    />
                  </Grid>
                </Box>
              </Tab>
              <Tab
                title="Key Features "
                className="choose_tab"
                onClick={() => navigate("#features")}
              >
                {/* <Box pad="medium">
                  <h2>Three</h2>
                </Box> */}
              </Tab>
              <Tab
                title="Technical Capabilities "
                className="choose_tab"
                onClick={() => navigate("#technical")}
              >
                {/* <Box pad="medium">
                  <h2>Two</h2>
                </Box> */}
              </Tab>

              <Tab
                title="Included Software "
                className="choose_tab"
                onClick={() => navigate("#software")}
              >
                {/* <Box pad="medium">
                  <h2>Four</h2>
                </Box> */}
              </Tab>
              <Tab
                title="System Requirements "
                className="choose_tab"
                onClick={() => navigate("#system-requirements")}
              >
                {/* <Box pad="medium">
                  <h2>Five</h2>
                </Box> */}
              </Tab>
            </Tabs>
          </div>
        </section>

        {/* Benefits */}

        <section
          id="features"
          className="benefits tw-container py-16 mt-7"
        >
          <p className="text-[16px] text-[#003591] uppercase font-semibold text-center">
            BIM Software Features
          </p>
          <h2 className="text-3xl text-gray-900 text-center font-extrabold mb-10">
            Key Benefits of BIM Software
          </h2>
          <Grid className="gap-32">
            {Data.benefits.map((bnf, i: number) => (
              <Grid
                id={bnf.id}
                className="md:p-4 lg:p-8 gap-5 lg:gap-12 lg:grid-cols-2"
                key={i}
              >
                <Box
                  className={
                    "lg:" + (i % 2 === 1 ? "order-1" : "order-0")
                  }
                >
                  <h3 className="text-3xl text-gray-900  font-extrabold tracking-[-1px] my-3">
                    {bnf.title}
                  </h3>
                  <p className="text-lg">{bnf.text}</p>
                  <div className="my-14">
                    {bnf.cta ? (
                      <Button
                        className="rounded-md bg-[#FBDA05] py-3 px-4 text-black font-[500] leading-6 text-[16px] w-fit"
                        onClick={() => setShowModal(true)}
                      >
                        {bnf.cta.title}
                      </Button>
                    ) : null}
                  </div>
                  {bnf.testimonial ? (
                    <Box
                      className="pt-6 grid gap-3"
                      style={{ borderTop: "1px solid #e5e7eb" }}
                    >
                      <text>{bnf.testimonial.text}</text>
                      <div className="flex flex-row items-center gap-3">
                        <Avatar src="/software/profile.png" />
                        <Text>{bnf.testimonial.author}</Text>
                      </div>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
                <div className="image_outer overflow-visible  relative">
                  <img
                    src={bnf.image}
                    alt="illustration"
                    className={
                      "lg:absolute top-[-100px] w-[90%] lg:w-[150%] max-w-none block " +
                      (i % 2 === 0
                        ? "lg:left-0 lg:order-1"
                        : "lg:right-0 lg:order-0")
                    }
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </section>

        {/* Features */}

        <section
          id="technical"
          className="bg-[#FBDA05] relative p-10 mt-10 mb-10"
        >
          <div className="tw-container">
            <div className=" md:px-8 lg:px-16">
              <p className="text-[16px] text-[#003591] uppercase font-semibold text-center">
                Technical Capabilities
              </p>
              <h2 className="text-4xl text-gray-900 text-center font-extrabold tracking-tight">
                Design, visualize, communicate, and deliver entire
                building and site projects with one convenient
                solution set.
              </h2>

              <Grid className="p-5 lg:p8 gap-6 lg:gap-12 md:grid-cols-2 lg:grid-cols-3">
                {Data.features.map((fr, i: number) => (
                  <div key={i}>
                    <div className="flex items-center  justify-center bg-[#003591] w-12 h-12 rounded-md">
                      <img src={fr.icon} loading="lazy" />
                    </div>
                    <h3 className="text-lg text-gray-900 font-[500] ">
                      {fr.title}
                    </h3>
                    <p className="text-base text-gray-600">
                      {fr.text}
                    </p>
                  </div>
                ))}
              </Grid>
            </div>
          </div>
        </section>

        <section id="software" className=" pb-10 relative">
          <div className="tw-container">
            <div className="md:px-8 lg:px-16">
              <p className="text-[16px] text-[#003591] uppercase font-semibold text-center">
                included software
              </p>
              <h2 className="text-4xl text-gray-900 text-center font-extrabold tracking-tight">
                The Ultimate BIM Software Bundle
              </h2>
              <p className="text-center">
                Building WorkSuite includes the following
                interoperable applications:
              </p>
              <Grid>
                <Grid
                  gap="30px"
                  className="includes_grid_header border border-x-0 border-solid border-gray-100"
                >
                  <p className="uppercase">CATEGORY</p>
                  <p className="uppercase">
                    RELEVANT SOFTWARE APPLICATION
                  </p>
                </Grid>
                {Data.included_software.map((row, i: number) => (
                  <Row key={i}>
                    <div className="w-[304px] flex flex-col justify-center">
                      <h3 className="text-black text-lg leading-7 font-semibold m-0 pb-1">
                        {row.title}
                      </h3>
                      <p className="text-gray-500 text-sm leading-5 font-normal m-0">
                        {row.description}
                      </p>
                    </div>
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 justify-start gap-8 ">
                      {row.items.map((item, j) => (
                        <Grid
                          key={j}
                          className="text-center w-[180px] justify-self-center"
                        >
                          <img
                            src={item.Avatar}
                            loading="lazy"
                            className="w-24 mx-auto mb-4"
                          />
                          <span className="text-sm text-gray-900 leading-5 font-medium">
                            {item.Name}
                          </span>
                          <span className="text-sm leading-5 font-medium text-[#003591]">
                            {item.Detail}
                          </span>
                        </Grid>
                      ))}
                    </div>
                  </Row>
                ))}
              </Grid>
            </div>
          </div>
        </section>

        {/* Requirments */}
        <section
          id="system-requirements"
          className="pt-8 md:pt-12 lg:pt-20 pb-10 relative"
        >
          <div className="tw-container">
            <div className="md:px-8 lg:px-16">
              <p className="text-[16px] text-[#003591] uppercase font-semibold">
                System Requirements
              </p>
              <h2 className="text-4xl text-gray-900  font-extrabold tracking-tight">
                Minimum Operating System Specification
              </h2>
              <p className="text-gray-500 text-xl leading-7 font-normal">
                Building WorkSuite delivers multiple products to users
                that will have different system requirements. Each
                software’s minimal requirements are located on the
                software’s product page. Below is the list of system
                requirements for OpenBuildings Designer.
              </p>
              <Requirments>
                {Data.requirments.map((req, i) => (
                  <>
                    <span>{req.device}</span>
                    <b>{req.req}</b>
                  </>
                ))}
              </Requirments>
            </div>
          </div>
        </section>

        {/* VIRTUOSO SUBSCRIPTION  */}
        <section className=" relative">
          <div className="tw-container p-5 lg:p-10 mb-8 rounded-md bg-[#FBDA05] ">
            <div className="md:px-8 lg:px-16">
              <p className="text-sm leading-5 font-medium tracking-wide uppercase text-gray-800 py-1 px-4 bg-white rounded-2xl w-fit">
                Professional Services
              </p>
              <h2 className="text-4xl text-gray-900  font-extrabold tracking-tight">
                BIM Support Services
              </h2>
              <p className="">
                Need help implementing BIM in your project? Leverage
                Bimeco's professional services, advanced technology
                infrastructure, and tested workflows on your next
                project.
              </p>
              <Link
                to="/services/playbook-contractor#bim-packages"
                className="bg-black text-white text-base leading-6 font-medium py-[9px] px-[17px] my-4 rounded-md block w-fit"
              >
                Our Packages
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Footer data={Data.footer} />

      {/* CTA */}
      {showModal ? (
        <SoftwareModal
          category="software-enquiry"
          setShowModal={setShowModal}
          referrrer={referrer}
          sku="Purchase Building WorkSuite"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default IndexPage;

export const Head = ({ location }) => (
  <SEO
    title={"BIM Software - 3D BIM Software for Buildings"}
    description={
      "Transform your project delivery with our 3D BIM software. Enjoy seamless CAD integration and interoperability with Revit, IFC, and AutoCAD. Enhance collaboration and precision today."
    }
    pathname={location.pathname}
  />
);
